import {
  FETCH_SINGLE_MESSAGE_SUCCESS,
  FETCH_RECEIVED_MESSAGES_SUCCESS,
  FETCH_SENT_MESSAGES_SUCCESS,
  SET_MESSAGE_READ,
  SET_MESSAGE_UNREAD,
  SET_MESSAGE_ACCESS,
  SET_MESSAGE_PASSWORD,
  SET_MESSAGE_SENT,
  MOVE_MESSAGE_TO_RECIPIENTFOLDER,
  MOVE_MESSAGE_TO_SENDERFOLDER,
  ADD_MESSAGE,
  ANSWER_MESSAGE,
  DELETE_MESSAGE,
  SAVE_MESSAGE,
  SAVE_MESSAGE_RECIPIENTS,
  SAVE_DRAFT_MESSAGE,
  UPDATE_DRAFT_MESSAGE,
  DELETE_DRAFT_MESSAGE,
  UPDATE_DRAFT_RECIPIENTS,
  REMOVE_MESSAGE_ID,
  SELECT_MESSAGE_ID,
  UNSELECT_MESSAGE_ID,
  CLEAR_MESSAGE_IDS
} from "actions/types";

import {
  message_single,
  messages_received,
  messages_sent,
  setMessageRead,
  setMessageUnread,
  setMessageAccess,
  setMessagePassword,
  assignRecipientMessageFolder,
  assignSenderMessageFolder,
  insMessage,
  reMessage,
  delMessage,
  storeMessage,
  messageSend,
  messageSystemSend,
  storeMessageRecipients,
} from "../api";

import { dataFailure } from "./general";

const fetchSingleMessageSuccess = (messages) => {
  return {
    type: FETCH_SINGLE_MESSAGE_SUCCESS,
    payload: messages,
  };
};

const fetchReceivedMessagesSuccess = (messages) => {
  return {
    type: FETCH_RECEIVED_MESSAGES_SUCCESS,
    payload: messages,
  };
};

const fetchSentMessagesSuccess = (messages) => {
  return {
    type: FETCH_SENT_MESSAGES_SUCCESS,
    payload: messages,
  };
};

export const fetchSingleMessage = (userId) => {
  return async (dispatch) => {
    try {
      const messagesJSONData = await dispatch(message_single(userId));
      await dispatch(fetchSingleMessageSuccess(messagesJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchReceivedMessages = (values) => {
  return async (dispatch) => {
    try {
      const messagesJSONData = await dispatch(messages_received(values));
      await dispatch(fetchReceivedMessagesSuccess(messagesJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchSentMessages = (values) => {
  return async (dispatch) => {
    try {
      const messagesJSONData = await dispatch(messages_sent(values));
      await dispatch(fetchSentMessagesSuccess(messagesJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setReadData = (values) => {
  return {
    type: SET_MESSAGE_READ,
    payload: values,
  };
};

export const setRead = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(setMessageRead(values));
      await dispatch(setReadData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setUnreadData = (values) => {
  return {
    type: SET_MESSAGE_UNREAD,
    payload: values,
  };
};

export const setUnread = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(setMessageUnread(values));
      await dispatch(setUnreadData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setAccessData = (values) => {
  return {
    type: SET_MESSAGE_ACCESS,
    payload: values,
  };
};

export const setAccessCompleted = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(setMessageAccess(values));
      await dispatch(setAccessData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
const setNewPasswordData = (values) => {
  return {
    type: SET_MESSAGE_PASSWORD,
    payload: values,
  };
};

export const setNewPasswordCompleted = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(setMessagePassword(values));
      await dispatch(setNewPasswordData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const sendMessageData = (values) => {
  return {
    type: SET_MESSAGE_SENT,
    payload: values,
  };
};

export const sendMessage = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(messageSend({ id: id }));
      await dispatch(sendMessageData(id));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const sendSystemMessage = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(messageSystemSend(values));
      await dispatch(sendMessageData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const assignRecipientFolderToMessage = (values) => {
  return {
    type: MOVE_MESSAGE_TO_RECIPIENTFOLDER,
    payload: values,
  };
};

export const setRecipientMessageFolder = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(assignRecipientMessageFolder(values));
      await dispatch(assignRecipientFolderToMessage(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const assignSenderFolderToMessage = (values) => {
  return {
    type: MOVE_MESSAGE_TO_SENDERFOLDER,
    payload: values,
  };
};

export const setSenderMessageFolder = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(assignSenderMessageFolder(values));
      await dispatch(assignSenderFolderToMessage(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const addMessageSuccess = (values) => {
  return {
    type: ADD_MESSAGE,
    payload: values
    // payload: {
    //   id: values.id,
    //   fromUserId: values.userId,
    //   toMode: "0",
    //   senderFolderId: "-5",
    //   recipientFolderId: "-3",
    //   title: "",
    //   message: "",
    //   recipients: [],
    // },
  };
};

export const addMessage = (userId) => {
  return async (dispatch) => {
    try {
      const messagesJSONData = await dispatch(insMessage({ userId: userId }));
      await dispatch(addMessageSuccess(messagesJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const addMessageRecipientsSuccess = (values) => {
  return {
    type: SAVE_MESSAGE_RECIPIENTS,
    payload: values,
  };
};

export const addMessageRecipients = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(storeMessageRecipients(values));
      await dispatch(addMessageRecipientsSuccess(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const answerMessageSuccess = (values) => {
  return {
    type: ANSWER_MESSAGE,
    payload: values,
  };
};

export const answerMessage = (values) => {
  return async (dispatch) => {
    try {
      const messagesJSONData = await dispatch(reMessage(values));
      await dispatch(answerMessageSuccess(messagesJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteMessageData = (id) => {
  return {
    type: DELETE_MESSAGE,
    payload: id,
  };
};

export const deleteMessage = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(delMessage(values));
      await dispatch(deleteMessageData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const saveMessageSuccess = (values) => {
  return {
    type: SAVE_MESSAGE,
    payload: values,
  };
};

export const saveMessage = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(storeMessage(values));
      await dispatch(saveMessageSuccess(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateDraftMessageSuccess = (values) => {
  return {
    type: UPDATE_DRAFT_MESSAGE,
    payload: values,
  };
};

export const updateDraftMessage = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(updateDraftMessageSuccess(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteDraftMessageSuccess = () => {
  return {
    type: DELETE_DRAFT_MESSAGE,
  };
};

export const deleteDraftMessage = () => {
  return async (dispatch) => {
    try {
      await dispatch(deleteDraftMessageSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateDraftRecipientsSuccess = (values) => {
  return {
    type: UPDATE_DRAFT_RECIPIENTS,
    payload: values,
  };
};

export const updateDraftRecipients = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(updateDraftRecipientsSuccess(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const saveDraftMessageSuccess = (values) => {
  return {
    type: SAVE_DRAFT_MESSAGE,
    payload: values,
  };
};

export const saveDraftMessage = () => {
  return async (dispatch, getState) => {
    const values = getState().messages.draftMessage;
    try {
      await dispatch(storeMessage(values));
      await dispatch(saveDraftMessageSuccess(values));
      await dispatch(storeMessageRecipients({...values.recipients, messageId: values.id}));
      await dispatch(addMessageRecipientsSuccess(values.recipients));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const removeMessageIdData = (value) => {
  return {
    type: REMOVE_MESSAGE_ID,
    payload: value,
  };
};

export const removeMessageId = (value) => {
  return async (dispatch) => {
    try {
      await dispatch(removeMessageIdData(value));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const selectMessageIdData = (value) => {
  return {
    type: SELECT_MESSAGE_ID,
    payload: value,
  };
};

export const selectMessageId = (value) => {
  return async (dispatch) => {
    try {
      await dispatch(selectMessageIdData(value));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const unselectMessageIdData = (value) => {
  return {
    type: UNSELECT_MESSAGE_ID,
    payload: value,
  };
};

export const unselectMessageId = (value) => {
  return async (dispatch) => {
    try {
      await dispatch(unselectMessageIdData(value));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const clearMessageIdData = () => {
  return {
    type: CLEAR_MESSAGE_IDS,
  };
};

export const clearMessageIds = () => {
  return async (dispatch) => {
    try {
      await dispatch(clearMessageIdData());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};