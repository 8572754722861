import React, { useState, useEffect, Fragment } from "react";
import { stringToHslColor } from "Components/Utilities";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { hasPermission } from "Components/Utilities";
import { useTranslation } from "react-i18next";
import { logoutUser, runUtility } from "../redux/index";
import store from "../redux/store";

import SomuraTooltip from "Components/SomuraTooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import ListItemIcon from "@mui/material/ListItemIcon";

import NewPassword from "./NewPassword";

import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import CommuteTwoToneIcon from "@mui/icons-material/CommuteTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import HistoryTwoToneIcon from "@mui/icons-material/HistoryTwoTone";
import NightsStayTwoToneIcon from "@mui/icons-material/NightsStayTwoTone";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import MeetingRoomTwoToneIcon from "@mui/icons-material/MeetingRoomTwoTone";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
// import BiotechSharpIcon from "@mui/icons-material/BiotechSharp";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";

import AboutSomura from "./AboutSomura";

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  return {
    loginState: state.loggeduser.LoginState,
    loggedUser,
    globalSettings: state.settings.GlobalSettings,
    accountLocked: state.general.Stats.accountLocked,
    rolls: state.security.Rolls,
    locations: state.company.Locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    runUtility: () => dispatch(runUtility()),
  };
};

const UserMenu = (props) => {
  const { t } = useTranslation();
  const loggedUser = props.loggedUser;
  const currentTheme = localStorage.getItem("theme") || "dark";
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPassword, setNewPassword] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  const openUser = Boolean(anchorEl);

  const handleUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (pageName) => {
    localStorage.setItem("lastPage", pageName);
    store.dispatch({
      type: "DISABLE_LOCATION_SELECTION",
      payload: false,
    });
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorEl(null);
  };

  const handlePWChange = () => {
    setNewPassword(true);
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorEl(null);
  };

  const handlePWClose = () => {
    setNewPassword(false);
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorEl(null);
  };

  const showAbout = () => {
    setOpenAbout(true);
    handleClose();
  };

  const setMode = (mode) => {
    localStorage.setItem("theme", mode);
    window.location.reload();
  };

  const handleMode = () => {
    setMode(currentTheme === "light" ? "dark" : "light");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("jwt");
    props.logoutUser();
    window.location.reload();
  };

  const handleHelp = () => {
    // Call external help files
    var helpUrl = "//" + window.location.host + "/doc/html/";
    if (window.location.hostname === "localhost") {
      helpUrl = "https://somura.netitwork.net/doc/html/index.html";
    }
    window.open(helpUrl, "_blank");
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.accountLocked) {
      handleLogout();
    }
    //eslint-disable-next-line
  }, [props.accountLocked]);

  // const handleDevUtility = () => {
  //   props.runUtility();
  //   setAnchorEl(null);
  // };

  return (
    <Fragment>
      {loggedUser && (
        <>
          <SomuraTooltip
            title={
              t("Logged in as ") +
              loggedUser.firstName +
              " " +
              loggedUser.lastName
            }
            placement="left"
          >
            <IconButton
              style={{ margin: "0 0.5rem" }}
              edge="end"
              aria-label="account of current user"
              aria-controls="usermenu"
              aria-haspopup="true"
              onClick={handleUserMenu}
              color="inherit"
              size="large"
            >
              <Avatar
                sx={{
                  color: "white",
                  backgroundColor: stringToHslColor(
                    loggedUser.initials ? loggedUser.initials : "?"
                  ),
                }}
              >
                {loggedUser.initials ? loggedUser.initials : "?"}
              </Avatar>
            </IconButton>
          </SomuraTooltip>

          <Menu
            sx={{
              marginTop: "-1rem",
            }}
            id="usermenu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openUser}
            onClose={() => handleClose("")}
          >
            <MenuItem
              disabled={true}
              sx={{
                fontSize: "1.2rem",
                color: "text.userMenuTitle",
              }}
            >
              {loggedUser?.listName}
            </MenuItem>
            <MenuItem
              disabled={true}
              sx={{
                fontSize: "1rem",
                fontWeight: 300,
                lineHeight: "0rem",
                color: "text.userMenuTitle",
              }}
            >
              {
                props.rolls?.find(
                  (R) => parseInt(R.id) === parseInt(loggedUser?.rollId)
                )?.rollName
              }
            </MenuItem>
            <MenuItem
              disabled={true}
              sx={{
                fontSize: "1rem",
                fontWeight: 300,
                lineHeight: "1rem",
                color: "text.userMenuTitle",
              }}
            >
              {
                props.locations?.find(
                  (C) => parseInt(C.id) === parseInt(loggedUser?.companyId)
                )?.locationName
              }
            </MenuItem>
            <MenuItem
              name="Person"
              component={NavLink}
              onClick={() => handleClose("Person")}
              to={"/Person"}
            >
              <ListItemIcon>
                <PersonOutlineTwoToneIcon />
              </ListItemIcon>
              {t("Personal data")}
            </MenuItem>
            {loggedUser?.isDeveloper && (
              <MenuItem
                name="Reminders"
                component={NavLink}
                onClick={() => handleClose("Reminders")}
                to={"/Reminders"}
              >
                <ListItemIcon>
                  <NotificationsNoneTwoToneIcon />
                </ListItemIcon>
                {t("Reminders")}
              </MenuItem>
            )}
            {loggedUser?.isDeveloper && (
              <MenuItem
                name="BusinessTrips"
                component={NavLink}
                onClick={() => handleClose("BusinessTrips")}
                to={"/BusinessTrips"}
              >
                <ListItemIcon>
                  <CommuteTwoToneIcon />
                </ListItemIcon>
                {t("Business trips")}
              </MenuItem>
            )}
            <MenuItem onClick={handlePWChange}>
              <ListItemIcon>
                <LockOpenTwoToneIcon />
              </ListItemIcon>
              {t("Change password")}
            </MenuItem>
            <MenuItem
              name="Settings"
              component={NavLink}
              onClick={() => handleClose("PersonalSettings")}
              to={"/PersonalSettings"}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              {t("Settings")}
            </MenuItem>
            <MenuItem name="AboutSomura" onClick={showAbout}>
              <ListItemIcon>
                <InfoTwoToneIcon />
              </ListItemIcon>
              {t("About somura")}
            </MenuItem>
            {hasPermission("showLog") && (
              <MenuItem
                name="Logfile"
                component={NavLink}
                onClick={() => handleClose("Log")}
                to={"/Log"}
              >
                <ListItemIcon>
                  <HistoryTwoToneIcon />
                </ListItemIcon>
                {t("Log file")}
              </MenuItem>
            )}
            {currentTheme === "light" && (
              <MenuItem onClick={handleMode}>
                <ListItemIcon>
                  <NightsStayTwoToneIcon />
                </ListItemIcon>
                {t("Dark mode")}
              </MenuItem>
            )}
            {currentTheme === "dark" && (
              <MenuItem onClick={handleMode}>
                <ListItemIcon>
                  <WbSunnyTwoToneIcon />
                </ListItemIcon>
                {t("Light mode")}
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <MeetingRoomTwoToneIcon />
              </ListItemIcon>
              {t("Logout")}
            </MenuItem>
            {loggedUser?.emailCompany === "walter.rank@netitwork.net" && (
              <MenuItem
                name="Developer"
                component={NavLink}
                onClick={() => handleClose("Developer")}
                to={"/Developer"}
              >
                <ListItemIcon>
                  <DeveloperBoardIcon />
                </ListItemIcon>
                {"Developer tools"}
              </MenuItem>
            )}
              <MenuItem name="Help" onClick={handleHelp}>
                <ListItemIcon>
                  <HelpTwoToneIcon />
                </ListItemIcon>
                {t("Help")}
              </MenuItem>
            {/* {loggedUser?.emailCompany === "walter.rank@netitwork.net" && (
              <MenuItem
                name="Test"
                component={NavLink}
                onClick={() => handleClose("Test")}
                to={"/Test"}
              >
                <ListItemIcon>
                  <BiotechSharpIcon />
                </ListItemIcon>
                {"Test"}
              </MenuItem>
            )} */}
          </Menu>
          {openAbout && <AboutSomura closeAbout={() => setOpenAbout(false)} />}
        </>
      )}
      {newPassword && <NewPassword closeNewPassword={handlePWClose} />}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
