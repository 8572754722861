import {
  FETCH_PERSON_SUCCESS,
  FETCH_PERSONS_MIN_SUCCESS,
  UPDATE_PERSON,
  UPLOAD_PERSON_PICTURE,
  ADD_PERSON,
  ADD_PERSON_ASSET,
  UPDATE_PERSON_ASSET,
  PERSONS_FAILURE,
  SET_PERSON_ID,
  ADD_PERSON_ENTITLEMENT,
  UPDATE_PERSON_ENTITLEMENT,
  DELETE_PERSON_ENTITLEMENT,
  ADD_SALARY,
  UPDATE_SALARY,
  DELETE_SALARY,
  ADD_COVSTATE,
  UPDATE_COVSTATE,
  ADD_PERSON_TIMEPLAN,
  UPDATE_PERSON_TIMEPLAN,
  DELETE_PERSON_TIMEPLAN,
} from "actions/types";
import { DateTime } from "luxon";
import { produce } from "immer";

const initialState = {
  Person: {},
  Persons: [],
  PersonsMin: [],
  error: "",
  personId: -1,
};

const personsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const PL = action.payload;
    let key;
    let personKey;
    let person;
    // eslint-disable-next-line
    switch (action.type) {
      case FETCH_PERSON_SUCCESS:
        draft.Person = PL;

        draft.error = "";
        break;
      case FETCH_PERSONS_MIN_SUCCESS:
        draft.PersonsMin = PL;

        draft.error = "";
        break;
      case PERSONS_FAILURE:
        draft.error = PL;
        break;
      case UPDATE_PERSON:
        personKey = draft.Persons.findIndex((P) => P.id === PL.id);
        draft.Persons[personKey] = PL;
        draft.Persons[personKey].listName = PL.lastName + ", " + PL.firstName;
        draft.Persons[personKey].longName = (
          PL.personNumber +
          " " +
          PL.lastName +
          ", " +
          PL.firstName
        ).trim();
        personKey = draft.PersonsMin.findIndex((P) => P.id === PL.id);
        draft.PersonsMin[personKey] = PL;
        draft.PersonsMin[personKey].listName =
          PL.lastName + ", " + PL.firstName;
        draft.PersonsMin[personKey].longName = (
          PL.personNumber +
          " " +
          PL.lastName +
          ", " +
          PL.firstName
        ).trim();
        break;
      case UPLOAD_PERSON_PICTURE:
        draft.Person.picture = PL.fileName
        break;
      case ADD_PERSON:
        draft.Persons = [
          ...draft.Persons,
          {
            ...PL,
            listName: PL.lastName + ", " + PL.firstName,
            longName: (
              PL.personNumber +
              " " +
              PL.lastName +
              ", " +
              PL.firstName
            ).trim(),
          },
        ];
        draft.PersonsMin = [
          ...draft.PersonsMin,
          {
            ...PL,
            listName: PL.lastName + ", " + PL.firstName,
            longName: (
              PL.personNumber +
              " " +
              PL.lastName +
              ", " +
              PL.firstName
            ).trim(),
          },
        ];
        break;
      case UPDATE_PERSON_ASSET:
        personKey = draft.Persons.findIndex((P) => P.id === PL.personId);
        person = draft.Persons[personKey];
        const assetKey = person.loanAssets.findIndex((LA) => LA.id === PL.id);
        var assets = person.loanAssets;
        if (PL.returnDate === "") {
          assets[assetKey] = PL;
        } else {
          assets.splice(assetKey, 1);
        }
        break;
      case ADD_PERSON_ASSET:
        personKey = draft.Persons.findIndex((P) => P.id === PL.personId);
        person = draft.Persons[personKey];
        person.loanAssets = [...person.loanAssets, PL];
        break;
      case SET_PERSON_ID:
        draft.personId = PL;
        break;
      case ADD_PERSON_ENTITLEMENT:
        draft.Person.entitlements = [...draft.Person.entitlements, PL];
        break;
      case UPDATE_PERSON_ENTITLEMENT:
        key = draft.Person.entitlements.findIndex((E) => E.id === PL.id);
        draft.Person.entitlements[key] = PL;
        break;
      case DELETE_PERSON_ENTITLEMENT:
        key = draft.Person.entitlements.findIndex((E) => E.id === PL.id);
        draft.Person.entitlements.splice(key, 1);
        break;
      case ADD_SALARY:
        draft.Person.wages = [...draft.Person.wages, PL];
        break;
      case UPDATE_SALARY:
        key = draft.Person.wages.findIndex((E) => E.id === PL.id);
        draft.Person.wages[key] = PL;
        break;
      case DELETE_SALARY:
        key = draft.Person.wages.findIndex((E) => E.id === PL.id);
        draft.Person.wages.splice(key, 1);
        break;
      case ADD_COVSTATE:
        draft.Person.covstates = [...draft.Person.covstates, PL];
        break;
      case UPDATE_COVSTATE:
        key = draft.Person.covstates.findIndex((E) => E.id === PL.id);
        draft.Person.covstates[key] = PL;
        break;
      case ADD_PERSON_TIMEPLAN:
        draft.Person.weeklyTimePlans = [...draft.Person.weeklyTimePlans, PL];
        draft.Person.weeklyTimePlans.sort(function (a, b) {
          return (
            DateTime.fromSQL(b.validSince) - DateTime.fromSQL(a.validSince)
          );
        });
        break;
      case UPDATE_PERSON_TIMEPLAN:
        key = draft.Person.weeklyTimePlans.findIndex((WTP) => WTP.id === PL.id);
        draft.Person.weeklyTimePlans[key] = PL;
        draft.Person.weeklyTimePlans.sort(function (a, b) {
          return (
            DateTime.fromSQL(b.validSince) - DateTime.fromSQL(a.validSince)
          );
        });
        break;
      case DELETE_PERSON_TIMEPLAN:
        key = draft.Person.weeklyTimePlans.findIndex((WTP) => WTP.id === PL.id);
        draft.Person.weeklyTimePlans.splice(key, 1);
        break;
      default:
        return state;
    }
  });

export default personsReducer;
