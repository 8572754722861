import {
  FETCH_COMPANY_LOCATIONS_SUCCESS,
  ADD_COMPANY_LOCATION,
  UPDATE_COMPANY_LOCATION,
  UPLOAD_LOGO,
  SET_SELECTED_LOCATION_ID,
} from "actions/types";

import { companyLocations, companyLocationUpdate, companyLocationAdd, logoUpload } from '../api'
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchCompanyLocationsSuccess = (Company) => {
  return {
    type: FETCH_COMPANY_LOCATIONS_SUCCESS,
    payload: Company
  }
}

export const fetchCompanyLocations = () => {
  return async (dispatch) => {
    try {
      const companyJSONData = await dispatch(companyLocations())
      await dispatch(fetchCompanyLocationsSuccess(companyJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const addCompanyData = (values) => {
  return {
    type: ADD_COMPANY_LOCATION,
    payload: values
  }
}

export const addCompany = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(companyLocationAdd(values));
      await dispatch(addCompanyData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message));
      
    }
  }
}

const updateCompanyData = (values) => {
  return {
    type: UPDATE_COMPANY_LOCATION,
    payload: values
  }
}

export const updateCompany = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(companyLocationUpdate(values));
      await dispatch(updateCompanyData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message));
      
    }
  }
}

const uploadLogoData = (values) => {
  return {
    type: UPLOAD_LOGO,
    payload: values
  }
}

export const uploadLogo = (values) => {
  return async (dispatch) => {
    try {
      const fileName = await dispatch(logoUpload(values));
      await dispatch(uploadLogoData(fileName))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message));
    }
  }
}

export const setLocationId = (values) => {
  return {
    type: SET_SELECTED_LOCATION_ID,
    payload: values
  };
}

