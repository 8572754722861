export const ItemTypes = {
  MESSAGE: "MESSAGE",
};

export const languages = [
  {
    languageId: "1",
    languageName: "de-DE",
    languageLongName: "Deutsch",
    languageMenuName: "DEU",
  },
  {
    languageId: "2",
    languageName: "en-US",
    languageLongName: "English",
    languageMenuName: "ENG",
  },
  {
    languageId: "3",
    languageName: "cs-CZ",
    languageLongName: "čeština",
    languageMenuName: "CES",
  },
];

export const months = [
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

export const rights = [
  {
    name: "seeAllCompanyLocations",
    grant: false,
    title: "All company locations",
    group: "Common",
    module: "",
  },
  {
    name: "showLog",
    grant: false,
    title: "Show log file",
    group: "Common",
    module: "",
  },
  {
    name: "showAtWorkToday",
    grant: false,
    title: "Show absent users in dashboard",
    group: "Common",
    module: "",
  },
  {
    name: "showOpenApprovements",
    grant: false,
    title: "Show open approvements in dashboard",
    group: "Common",
    module: "",
  },
  {
    name: "showNextEvents",
    grant: false,
    title: "Show upcoming events in dashboard",
    group: "Common",
    module: "",
  },
  {
    name: "showDashboard",
    grant: false,
    title: "Show dashboard",
    group: "Menus",
    module: "",
  },
  {
    name: "showMonthCalendar",
    grant: false,
    title: "Show month calendar",
    group: "Menus",
    module: "",
  },
  {
    name: "showPersons",
    grant: false,
    title: "Show persons",
    group: "Menus",
    module: "",
  },
  {
    name: "showArticles",
    grant: false,
    title: "Show articles",
    group: "Menus",
    module: "Articles",
  },
  {
    name: "planAbsence",
    grant: false,
    title: "Plan absence",
    group: "Absence",
    module: "",
  },
  {
    name: "requestAbsence",
    grant: false,
    title: "Request absence",
    group: "Absence",
    module: "",
  },
  {
    name: "registerAbsence",
    grant: false,
    title: "Register absence",
    group: "Absence",
    module: "",
  },
  {
    name: "deleteAbsence",
    grant: false,
    title: "Delete absence",
    group: "Absence",
    module: "",
  },
  {
    name: "approveAbsence",
    grant: false,
    title: "Approve absence",
    group: "Absence",
    module: "",
  },
  {
    name: "resetAbsenceState",
    grant: false,
    title: "Reset absence state",
    group: "Absence",
    module: "",
  },
  {
    name: "deleteOwnPlannedAbsence",
    grant: false,
    title: "Delete own planned absence",
    group: "Absence",
    module: "",
  },
  {
    name: "deleteApprovedAbsence",
    grant: false,
    title: "Delete approved absence",
    group: "Absence",
    module: "",
  },
  {
    name: "showOtherAbsenceType",
    grant: false,
    title: "Show absence type of other users",
    group: "Absence",
    module: "",
  },
  {
    name: "showAllDepartments",
    grant: false,
    title: "Show all departments",
    group: "Calendars",
    module: "",
  },
  {
    name: "YearCalPersonsSelectable",
    grant: false,
    title: "Persons selectable (Year calendar)",
    group: "Calendars",
    module: "",
  },
  {
    name: "sendToAllUsers",
    grant: false,
    title: "Send messages to all users",
    group: "Messages",
    module: "Messages",
  },
  {
    name: "showSettingsMenu",
    grant: false,
    title: "Show settings menu",
    group: "Settings",
    module: "",
  },
  {
    name: "administerCompanyData",
    grant: false,
    title: "Company data",
    group: "Settings",
    module: "",
  },
  {
    name: "administerAddressData",
    grant: false,
    title: "Addresses",
    group: "Settings",
    module: "",
  },
  {
    name: "administerCalendarData",
    grant: false,
    title: "Calendars",
    group: "Settings",
    module: "",
  },
  {
    name: "administerAbsenceTypes",
    grant: false,
    title: "Absence types",
    group: "Settings",
    module: "",
  },
  {
    name: "administerDocuments",
    grant: false,
    title: "Documents",
    group: "Settings",
    module: "",
  },
  {
    name: "administerArticleData",
    grant: false,
    title: "Articles",
    group: "Settings",
    module: "Articles",
  },
  {
    name: "administerTimeTracking",
    grant: false,
    title: "Time tracking settings",
    group: "Settings",
    module: "TimeTracking",
  },
  {
    name: "administerRollsRights",
    grant: false,
    title: "Rolls and rights",
    group: "Settings",
    module: "",
  },
  {
    name: "administerSystemSettings",
    grant: false,
    title: "System settings",
    group: "Settings",
    module: "",
  },
  {
    name: "administerOtherPersons",
    grant: false,
    title: "Other person's data",
    group: "Employees",
    module: "",
  },
  {
    name: "mayLockAccount",
    grant: false,
    title: "May lock account",
    group: "Employees",
    module: "",
  },
  {
    name: "mayResetPassword",
    grant: false,
    title: "May assign new password",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonCompany",
    grant: false,
    title: "Company data",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonPrivate",
    grant: false,
    title: "Private data",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonRoll",
    grant: false,
    title: "Roll",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonBilling",
    grant: false,
    title: "Billing data",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonSalary",
    grant: false,
    title: "Salary data",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonEntitlement",
    grant: false,
    title: "Vacation entitlement",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonTimes",
    grant: false,
    title: "Time tracking data",
    group: "Employees",
    module: "TimeTracking",
  },
  {
    name: "administerPersonAssets",
    grant: false,
    title: "Loan assets data",
    group: "Employees",
    module: "Loan",
  },
  {
    name: "administerPersonCovidState",
    grant: false,
    title: "Covid state",
    group: "Employees",
    module: "CovState",
  },
  {
    name: "administerPersonRecurringAbsences",
    grant: false,
    title: "Recurring absences",
    group: "Employees",
    module: "",
  },
  {
    name: "administerPersonRemarks",
    grant: false,
    title: "Remarks",
    group: "Employees",
    module: "",
  },
];

export const reminderTimes = [
  { id: 1, number: 5, text: "minutes", interval: {minutes: 5} },
  { id: 2, number: 10, text: "minutes", interval: {minutes: 10} },
  { id: 3, number: 15, text: "minutes", interval: {minutes: 15} },
  { id: 4, number: 30, text: "minutes", interval: {minutes: 30} },
  { id: 5, number: 45, text: "minutes", interval: {minutes: 45} },
  { id: 6, number: 1, text: "hour", interval: {hours: 1} },
  { id: 7, number: 2, text: "hours", interval: {hours: 2} },
  { id: 8, number: 4, text: "hours", interval: {hours: 4} },
  { id: 9, number: 6, text: "hours", interval: {hours: 6} },
  { id: 10, number: 8, text: "hours", interval: {hours: 8} },
  { id: 11, number: 12, text: "hours", interval: {hours: 12} },
  { id: 12, number: 18, text: "hours", interval: {hours: 18} },
  { id: 13, number: 1, text: "day", interval: {days: 1} },
  { id: 14, number: 2, text: "days", interval: {days: 2} },
  { id: 15, number: 3, text: "days", interval: {days: 3} },
  { id: 16, number: 4, text: "days", interval: {days: 4} },
  { id: 17, number: 5, text: "days", interval: {days: 5} },
  { id: 18, number: 6, text: "days", interval: {days: 6} },
  { id: 19, number: 1, text: "week", interval: {weeks: 1} },
  { id: 20, number: 2, text: "weeks", interval: {weeks: 2} },
  { id: 21, number: 3, text: "weeks", interval: {weeks: 3} },
  { id: 22, number: 4, text: "weeks", interval: {weeks: 4} },
];

export const icons = [
  {
    id: "1",
    name: "Album",
    path: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 14.5c-2.49 0-4.5-2.01-4.5-4.5S9.51 7.5 12 7.5s4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5zm0-5.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z",
  },
  {
    id: "2",
    name: "Adjust",
    path: "M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z",
  },
  {
    id: "3",
    name: "BrightnessLow",
    path: "M20 15.31L23.31 12 20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z",
  },
  {
    id: "4",
    name: "Cake",
    path: "M12 6c1.11 0 2-.9 2-2 0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm4.6 9.99l-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 16.64 5.88 17 4.96 17c-.73 0-1.4-.23-1.96-.61V21c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01zM18 9h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V12C21 10.34 19.66 9 18 9z",
  },
  {
    id: "5",
    name: "Camera",
    path: "M9.4 10.5l4.77-8.26C13.47 2.09 12.75 2 12 2c-2.4 0-4.6.85-6.32 2.25l3.66 6.35.06-.1zM21.54 9c-.92-2.92-3.15-5.26-6-6.34L11.88 9h9.66zm.26 1h-7.49l.29.5 4.76 8.25C21 16.97 22 14.61 22 12c0-.69-.07-1.35-.2-2zM8.54 12l-3.9-6.75C3.01 7.03 2 9.39 2 12c0 .69.07 1.35.2 2h7.49l-1.15-2zm-6.08 3c.92 2.92 3.15 5.26 6 6.34L12.12 15H2.46zm11.27 0l-3.9 6.76c.7.15 1.42.24 2.17.24 2.4 0 4.6-.85 6.32-2.25l-3.66-6.35-.93 1.6z",
  },
  {
    id: "6",
    name: "ChangeHistory",
    path: "M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z",
  },
  {
    id: "7",
    name: "CheckBoxOutlineBlank",
    path: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z",
  },
  {
    id: "8",
    name: "ChildCare",
    path: "M22.94 12.66c.04-.21.06-.43.06-.66s-.02-.45-.06-.66c-.25-1.51-1.36-2.74-2.81-3.17-.53-1.12-1.28-2.1-2.19-2.91C16.36 3.85 14.28 3 12 3s-4.36.85-5.94 2.26c-.92.81-1.67 1.8-2.19 2.91-1.45.43-2.56 1.65-2.81 3.17-.04.21-.06.43-.06.66s.02.45.06.66c.25 1.51 1.36 2.74 2.81 3.17.52 1.11 1.27 2.09 2.17 2.89C7.62 20.14 9.71 21 12 21s4.38-.86 5.97-2.28c.9-.8 1.65-1.79 2.17-2.89 1.44-.43 2.55-1.65 2.8-3.17zM19 14c-.1 0-.19-.02-.29-.03-.2.67-.49 1.29-.86 1.86C16.6 17.74 14.45 19 12 19s-4.6-1.26-5.85-3.17c-.37-.57-.66-1.19-.86-1.86-.1.01-.19.03-.29.03-1.1 0-2-.9-2-2s.9-2 2-2c.1 0 .19.02.29.03.2-.67.49-1.29.86-1.86C7.4 6.26 9.55 5 12 5s4.6 1.26 5.85 3.17c.37.57.66 1.19.86 1.86.1-.01.19-.03.29-.03 1.1 0 2 .9 2 2s-.9 2-2 2zM7.5 14c.76 1.77 2.49 3 4.5 3s3.74-1.23 4.5-3h-9z",
  },
  {
    id: "9",
    name: "ControlCamera",
    path: "M15.54 5.54L13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10l-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92l1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10l1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z",
  },
  {
    id: "10",
    name: "EmojiEmoticons",
    path: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM8.5 8c.83 0 1.5.67 1.5 1.5S9.33 11 8.5 11 7 10.33 7 9.5 7.67 8 8.5 8zM12 18c-2.28 0-4.22-1.66-5-4h10c-.78 2.34-2.72 4-5 4zm3.5-7c-.83 0-1.5-.67-1.5-1.5S14.67 8 15.5 8s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z",
  },
  {
    id: "11",
    name: "Face",
    path: "M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z",
  },
  {
    id: "12",
    name: "Favorite",
    path: "M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z",
  },
  {
    id: "13",
    name: "Flare",
    path: "M7 11H1v2h6v-2zm2.17-3.24L7.05 5.64 5.64 7.05l2.12 2.12 1.41-1.41zM13 1h-2v6h2V1zm5.36 6.05l-1.41-1.41-2.12 2.12 1.41 1.41 2.12-2.12zM17 11v2h6v-2h-6zm-5-2c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm2.83 7.24l2.12 2.12 1.41-1.41-2.12-2.12-1.41 1.41zm-9.19.71l1.41 1.41 2.12-2.12-1.41-1.41-2.12 2.12zM11 23h2v-6h-2v6z",
  },
  {
    id: "14",
    name: "Games",
    path: "M15 7.5V2H9v5.5l3 3 3-3zM7.5 9H2v6h5.5l3-3-3-3zM9 16.5V22h6v-5.5l-3-3-3 3zM16.5 9l-3 3 3 3H22V9h-5.5z",
  },
  {
    id: "15",
    name: "House",
    path: "M19 9.3V4h-3v2.6L12 3 2 12h3v8h5v-6h4v6h5v-8h3l-3-2.7zm-9 .7c0-1.1.9-2 2-2s2 .9 2 2h-4z",
  },
  {
    id: "16",
    name: "Info",
    path: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z",
  },
  {
    id: "17",
    name: "InsertEmoticon",
    path: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z",
  },
  {
    id: "18",
    name: "Language",
    path: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z",
  },
  {
    id: "19",
    name: "LocalFlorist",
    path: "M12 22c4.97 0 9-4.03 9-9-4.97 0-9 4.03-9 9zM5.6 10.25c0 1.38 1.12 2.5 2.5 2.5.53 0 1.01-.16 1.42-.44l-.02.19c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5l-.02-.19c.4.28.89.44 1.42.44 1.38 0 2.5-1.12 2.5-2.5 0-1-.59-1.85-1.43-2.25.84-.4 1.43-1.25 1.43-2.25 0-1.38-1.12-2.5-2.5-2.5-.53 0-1.01.16-1.42.44l.02-.19C14.5 2.12 13.38 1 12 1S9.5 2.12 9.5 3.5l.02.19c-.4-.28-.89-.44-1.42-.44-1.38 0-2.5 1.12-2.5 2.5 0 1 .59 1.85 1.43 2.25-.84.4-1.43 1.25-1.43 2.25zM12 5.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8s1.12-2.5 2.5-2.5zM3 13c0 4.97 4.03 9 9 9 0-4.97-4.03-9-9-9z",
  },
  {
    id: "20",
    name: "MyLocation",
    path: "M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z",
  },
  {
    id: "21",
    name: "Notifications",
    path: "M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z",
  },
  {
    id: "22",
    name: "Pages",
    path: "M3 5v6h5L7 7l4 1V3H5c-1.1 0-2 .9-2 2zm5 8H3v6c0 1.1.9 2 2 2h6v-5l-4 1 1-4zm9 4l-4-1v5h6c1.1 0 2-.9 2-2v-6h-5l1 4zm2-14h-6v5l4-1-1 4h5V5c0-1.1-.9-2-2-2z",
  },
  {
    id: "23",
    name: "Place",
    path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  },
  {
    id: "24",
    name: "Spa",
    path: "M15.49 9.63c-.18-2.79-1.31-5.51-3.43-7.63-2.14 2.14-3.32 4.86-3.55 7.63 1.28.68 2.46 1.56 3.49 2.63 1.03-1.06 2.21-1.94 3.49-2.63zm-6.5 2.65c-.14-.1-.3-.19-.45-.29.15.11.31.19.45.29zm6.42-.25c-.13.09-.27.16-.4.26.13-.1.27-.17.4-.26zM12 15.45C9.85 12.17 6.18 10 2 10c0 5.32 3.36 9.82 8.03 11.49.63.23 1.29.4 1.97.51.68-.12 1.33-.29 1.97-.51C18.64 19.82 22 15.32 22 10c-4.18 0-7.85 2.17-10 5.45z",
  },
  {
    id: "25",
    name: "Star",
    path: "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
  },
  {
    id: "26",
    name: "Texture",
    path: "M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z",
  },
];
