import {
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLEMODELS_SUCCESS,
  FETCH_ARTICLETYPES_SUCCESS,
  FETCH_MOVEMENTTYPES_SUCCESS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_REASONS_SUCCESS,
  FETCH_STATES_SUCCESS,
  ARTICLES_FAILURE,
  SET_ARTICLE_ID,
  SET_ARTICLEMODEL_ID,
  SET_ARTICLETYPE_ID,
  SET_MOVEMENTTYPE_ID,
  SET_LOCATION_ID,
  SET_REASON_ID,
  SET_STATE_ID,
  CREATE_ARTICLE,
  CREATE_ARTICLEMODEL,
  CREATE_ARTICLETYPE,
  CREATE_MOVEMENTTYPE,
  CREATE_MOVEMENT,
  CREATE_LOCATION,
  CREATE_REASON,
  CREATE_STATE,
  UPDATE_ARTICLE,
  UPDATE_ARTICLEMODEL,
  UPDATE_ARTICLETYPE,
  UPDATE_MOVEMENTTYPE,
  UPDATE_MOVEMENT,
  UPDATE_LOCATION,
  UPDATE_REASON,
  UPDATE_STATE,
  DELETE_ARTICLEMODEL,
  DELETE_ARTICLETYPE,
  DELETE_MOVEMENT,
  UPLOAD_ARTICLE_PICTURE,
  UPLOAD_ARTICLEMODEL_PICTURE,
  UPLOAD_ARTICLETYPE_PICTURE
} from "actions/types";

import {
  articles,
  articleModels,
  articleTypes,
  articleReasons,
  articleStates,
  movementTypes,
  locations,
  articleCreate,
  articleModelCreate,
  articleTypeCreate,
  articleReasonCreate,
  articleStateCreate,
  movementCreate,
  movementTypeCreate,
  locationCreate,
  articleUpdate,
  articleModelUpdate,
  articleTypeUpdate,
  articleReasonUpdate,
  articleStateUpdate,
  movementTypeUpdate,
  movementUpdate,
  articleModelDelete,
  articleTypeDelete,
  movementDelete,
  locationUpdate,
  articlePictureUpload,
  articleModelPictureUpload,
  articleTypePictureUpload
} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

// Actions for articles

const articlesFailure = (err) => {
  return {
    type: ARTICLES_FAILURE,
    payload: err,
  };
};


const fetchArticlesSuccess = (values) => {
  return {
    type: FETCH_ARTICLES_SUCCESS,
    payload: values,
  };
};

export const fetchArticles = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articles());
      await dispatch(fetchArticlesSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

export const setSelectedArticleId = (value) => {
  return {
    type: SET_ARTICLE_ID,
    payload: value
  }
}

const createArticleData = (values) => {
  return {
    type: CREATE_ARTICLE,
    payload: values,
  };
};

export const createArticle = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleCreate(values));
      await dispatch(createArticleData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

const updateArticleData = (values) => {
  return {
    type: UPDATE_ARTICLE,
    payload: values,
  };
};

export const updateArticle = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleUpdate(values));
      await dispatch(updateArticleData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

// Actions for Models

const fetchArticleModelsSuccess = (values) => {
  return {
    type: FETCH_ARTICLEMODELS_SUCCESS,
    payload: values,
  };
};

export const fetchArticleModels = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleModels());
      await dispatch(fetchArticleModelsSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

export const setSelectedModelId = (value) => {
  return {
    type: SET_ARTICLEMODEL_ID,
    payload: value
  }
}

const createArticleModelData = (values) => {
  return {
    type: CREATE_ARTICLEMODEL,
    payload: values,
  };
};

export const createArticleModel = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleModelCreate(values));
      await dispatch(createArticleModelData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

const updateArticleModelData = (values) => {
  return {
    type: UPDATE_ARTICLEMODEL,
    payload: values,
  };
};

export const updateArticleModel = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleModelUpdate(values));
      await dispatch(updateArticleModelData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

const deleteArticleModelData = (values) => {
  return {
    type: DELETE_ARTICLEMODEL,
    payload: values,
  };
};

export const deleteArticleModel = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleModelDelete(values));
      await dispatch(deleteArticleModelData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

// Actions for Articletypes

const fetchArticleTypesSuccess = (values) => {
  return {
    type: FETCH_ARTICLETYPES_SUCCESS,
    payload: values,
  };
};

export const fetchArticleTypes = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleTypes());
      await dispatch(fetchArticleTypesSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

export const setSelectedArticleTypeId = (value) => {
  return {
    type: SET_ARTICLETYPE_ID,
    payload: value,
  };
};

const createArticleTypeData = (values) => {
  return {
    type: CREATE_ARTICLETYPE,
    payload: values
  }
}

export const createArticleType = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleTypeCreate(values))
      await dispatch(createArticleTypeData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      await dispatch(dataFailure(err.message))
    }
  }
}

const updateArticleTypeData = (values) => {
  return {
    type: UPDATE_ARTICLETYPE,
    payload: values
  }
}

export const updateArticleType = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleTypeUpdate(values))
      await dispatch(updateArticleTypeData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

const deleteArticleTypeData = (values) => {
  return {
    type: DELETE_ARTICLETYPE,
    payload: values,
  };
};

export const deleteArticleType = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleTypeDelete(values));
      await dispatch(deleteArticleTypeData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

// Actions for Reasons

const fetchReasonsSuccess = (values) => {
  return {
    type: FETCH_REASONS_SUCCESS,
    payload: values,
  };
};

export const fetchReasons = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleReasons());
      await dispatch(fetchReasonsSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

const createArticleReasonData = (values) => {
  return {
    type: CREATE_REASON,
    payload: values
  }
}

export const createArticleReason = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleReasonCreate(values))
      await dispatch(createArticleReasonData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      await dispatch(dataFailure(err.message))
    }
  }
}

const updateArticleReasonData = (values) => {
  return {
    type: UPDATE_REASON,
    payload: values
  }
}

export const updateArticleReason = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleReasonUpdate(values))
      await dispatch(updateArticleReasonData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

export const setSelectedReasonId = (value) => {
  return {
    type: SET_REASON_ID,
    payload: value,
  };
};

// Actions for States

const fetchStatesSuccess = (values) => {
  return {
    type: FETCH_STATES_SUCCESS,
    payload: values,
  };
};

export const fetchStates = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleStates());
      await dispatch(fetchStatesSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

const createArticleStateData = (values) => {
  return {
    type: CREATE_STATE,
    payload: values
  }
}

export const createArticleState = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(articleStateCreate(values))
      await dispatch(createArticleStateData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      await dispatch(dataFailure(err.message))
    }
  }
}

const updateArticleStateData = (values) => {
  return {
    type: UPDATE_STATE,
    payload: values
  }
}

export const updateArticleState = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(articleStateUpdate(values))
      await dispatch(updateArticleStateData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

export const setSelectedStateId = (value) => {
  return {
    type: SET_STATE_ID,
    payload: value,
  };
};

// Actions for Movementtypes

const fetchMovementTypesSuccess = (values) => {
  return {
    type: FETCH_MOVEMENTTYPES_SUCCESS,
    payload: values,
  };
};

export const fetchMovementTypes = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(movementTypes());
      await dispatch(fetchMovementTypesSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

export const setSelectedMovementTypeId = (value) => {
  return {
    type: SET_MOVEMENTTYPE_ID,
    payload: value,
  };
};

const createMovementTypeData = (values) => {
  return {
    type: CREATE_MOVEMENTTYPE,
    payload: values
  }
}

export const createMovementType = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(movementTypeCreate(values))
      await dispatch(createMovementTypeData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

const updateMovementTypeData = (values) => {
  return {
    type: UPDATE_MOVEMENTTYPE,
    payload: values
  }
}

export const updateMovementType = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(movementTypeUpdate(values))
      await dispatch(updateMovementTypeData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

const createMovementData = (values) => {
  return {
    type: CREATE_MOVEMENT,
    payload: values,
  };
};

export const createMovement = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(movementCreate(values));
      await dispatch(createMovementData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(articlesFailure(err));
      ;
    }
  };
};

const updateMovementData = (values) => {
  return {
    type: UPDATE_MOVEMENT,
    payload: values
  }
}

export const updateMovement = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(movementUpdate(values))
      await dispatch(updateMovementData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

const deleteMovementData = (values) => {
  return {
    type: DELETE_MOVEMENT,
    payload: values
  }
}

export const deleteMovement = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(movementDelete(values))
      await dispatch(deleteMovementData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

// Actions for Locations
const fetchLocationsSuccess = (values) => {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    payload: values,
  };
};

export const fetchLocations = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(locations());
      await dispatch(fetchLocationsSuccess(JSONData));
    } catch (err) {
      await dispatch(articlesFailure(err));
    }
  };
};

export const setSelectedLocationId = (value) => {
  return {
    type: SET_LOCATION_ID,
    payload: value,
  };
};

const createLocationData = (values) => {
  return {
    type: CREATE_LOCATION,
    payload: values
  }
}

export const createLocation = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(locationCreate(values))
      await dispatch(createLocationData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

const updateLocationData = (values) => {
  return {
    type: UPDATE_LOCATION,
    payload: values
  }
}

export const updateLocation = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(locationUpdate(values))
      await dispatch(updateLocationData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(articlesFailure(err))
      
    }
  }
}

const uploadArticlePictureData = (values) => {
  return {
    type: UPLOAD_ARTICLE_PICTURE,
    payload: values,
  };
}

export const uploadArticlePicture = (values) => {
  return async (dispatch) => {
    try {
      const fileName = await dispatch(articlePictureUpload(values));
      await dispatch(uploadArticlePictureData(fileName));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message));
      
    }
  }
}

const uploadArticleModelPictureData = (values) => {
  return {
    type: UPLOAD_ARTICLEMODEL_PICTURE,
    payload: values,
  };
}

export const uploadArticleModelPicture = (values) => {
  return async (dispatch) => {
    try {
      const fileName = await dispatch(articleModelPictureUpload(values));
      await dispatch(uploadArticleModelPictureData(fileName));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message));
      
    }
  }
}

const uploadArticleTypePictureData = (values) => {
  return {
    type: UPLOAD_ARTICLETYPE_PICTURE,
    payload: values,
  };
}

export const uploadArticleTypePicture = (values) => {
  return async (dispatch) => {
    try {
      const fileName = await dispatch(articleTypePictureUpload(values));
      await dispatch(uploadArticleTypePictureData(fileName));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message));
      
    }
  }
}