import {
  FETCH_DAILY_TIME_PLANS_SUCCESS,
  CREATE_DAILY_TIME_PLAN,
  UPDATE_DAILY_TIME_PLAN,
  DELETE_DAILY_TIME_PLAN,
  COPY_DAILY_TIME_PLAN,
  SET_DAILY_TIME_PLAN_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  DailyTimePlans: [],
  selectedDailyTimePlanId: -1,
  error: "",
};

const dailyTimePlansReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_DAILY_TIME_PLANS_SUCCESS:
        draft.DailyTimePlans = action.payload;
        
        draft.error = "";
        break;
      case CREATE_DAILY_TIME_PLAN:
        draft.DailyTimePlans = [...draft.DailyTimePlans, action.payload];
        
        draft.error = "";
        break;
      case UPDATE_DAILY_TIME_PLAN:
        key = draft.DailyTimePlans.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.DailyTimePlans[key] = action.payload;
        
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case DELETE_DAILY_TIME_PLAN:
        key = draft.DailyTimePlans.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.DailyTimePlans.splice(key, 1);
        
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case COPY_DAILY_TIME_PLAN:
        draft.DailyTimePlans = [...draft.DailyTimePlans, action.payload];
        draft.selectedDailyTimePlanId = action.payload.id;
        break;
      case SET_DAILY_TIME_PLAN_ID:
        draft.selectedDailyTimePlanId = action.payload;
        break;
      default:
        return state;
    }
  });

export default dailyTimePlansReducer;
