import {
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLEMODELS_SUCCESS,
  FETCH_ARTICLETYPES_SUCCESS,
  FETCH_MOVEMENTTYPES_SUCCESS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_REASONS_SUCCESS,
  FETCH_STATES_SUCCESS,
  SET_ARTICLE_ID,
  SET_ARTICLEMODEL_ID,
  SET_ARTICLETYPE_ID,
  SET_MOVEMENTTYPE_ID,
  SET_LOCATION_ID,
  SET_REASON_ID,
  SET_STATE_ID,
  CREATE_ARTICLE,
  CREATE_ARTICLEMODEL,
  CREATE_ARTICLETYPE,
  CREATE_MOVEMENTTYPE,
  CREATE_MOVEMENT,
  CREATE_LOCATION,
  CREATE_REASON,
  CREATE_STATE,
  UPDATE_ARTICLE,
  UPDATE_ARTICLEMODEL,
  UPDATE_ARTICLETYPE,
  UPDATE_MOVEMENTTYPE,
  UPDATE_MOVEMENT,
  UPDATE_LOCATION,
  UPDATE_REASON,
  UPDATE_STATE,
  DELETE_ARTICLEMODEL,
  DELETE_ARTICLETYPE,
  DELETE_MOVEMENT,
  UPLOAD_ARTICLE_PICTURE,
  UPLOAD_ARTICLEMODEL_PICTURE,
  UPLOAD_ARTICLETYPE_PICTURE,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Articles: [],
  ArticleModels: [],
  ArticleTypes: [],
  Reasons: [],
  States: [],
  MovementTypes: [],
  Locations: [],
  selectedArticleId: -1,
  selectedArticleModelId: -1,
  selectedArticleTypeId: -1,
  selectedMovementTypeId: -1,
  selectedLocationId: -1,
  selectedReasonId: -1,
  selectedStateId: -1,
};

const articlesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    let subkey = 0;
    switch (action.type) {
      case FETCH_ARTICLES_SUCCESS:
        draft.Articles = action.payload;
        break;
      case FETCH_ARTICLEMODELS_SUCCESS:
        draft.ArticleModels = action.payload;
        break;
      case FETCH_ARTICLETYPES_SUCCESS:
        draft.ArticleTypes = action.payload;
        break;
      case FETCH_MOVEMENTTYPES_SUCCESS:
        draft.MovementTypes = action.payload;
        break;
      case FETCH_LOCATIONS_SUCCESS:
        draft.Locations = action.payload;
        break;
      case FETCH_REASONS_SUCCESS:
        draft.Reasons = action.payload;
        break;
      case FETCH_STATES_SUCCESS:
        draft.States = action.payload;
        break;
      case SET_ARTICLE_ID:
        draft.selectedArticleId = action.payload;
        break;
      case SET_ARTICLEMODEL_ID:
        draft.selectedArticleModelId = action.payload;
        break;
      case SET_ARTICLETYPE_ID:
        draft.selectedArticleTypeId = action.payload;
        break;
      case SET_MOVEMENTTYPE_ID:
        draft.selectedMovementTypeId = action.payload;
        break;
      case SET_LOCATION_ID:
        draft.selectedLocationId = action.payload;
        break;
      case SET_REASON_ID:
        draft.selectedReasonId = action.payload;
        break;
      case SET_STATE_ID:
        draft.selectedStateId = action.payload;
        break;
      case CREATE_ARTICLE:
        draft.Articles = [
          ...draft.Articles,
          {
            ...action.payload,
            movements: [
              action.payload.firstMovement,
              action.payload.nextMovement,
            ],
          },
        ];
        break;
      case CREATE_ARTICLEMODEL:
        draft.ArticleModels = [...draft.ArticleModels, action.payload];
        draft.ArticleModels.sort((a, b) =>
          a.modelName.toUpperCase() > b.modelName.toUpperCase() ? 1 : -1
        );
        draft.selectedArticleModelId = action.payload.id;
        break;
      case CREATE_ARTICLETYPE:
        draft.ArticleTypes = [...draft.ArticleTypes, action.payload];
        draft.ArticleTypes.sort((a, b) =>
          a.articleTypeName.toUpperCase() > b.articleTypeName.toUpperCase()
            ? 1
            : -1
        );
        draft.selectedArticleTypeId = action.payload.id;
        break;
      case CREATE_MOVEMENTTYPE:
        draft.MovementTypes = [...draft.MovementTypes, action.payload];
        break;
      case CREATE_LOCATION:
        draft.Locations = [...draft.Locations, action.payload];
        break;
      case CREATE_REASON:
        draft.Reasons = [...draft.Reasons, action.payload];
        break;
      case CREATE_STATE:
        draft.States = [...draft.States, action.payload];
        break;
      case UPDATE_ARTICLE:
        key = draft.Articles.findIndex((AT) => AT.id === action.payload.id);
        draft.Articles[key] = action.payload;
        if (parseInt(action.payload.nextMovement.movementTypeId) > 0)
          // Add movement
          draft.Articles[key].movements = [
            action.payload.nextMovement,
            ...draft.Articles[key].movements,
          ];
        break;
      case UPDATE_ARTICLEMODEL:
        key = draft.ArticleModels.findIndex(
          (AT) => AT.id === action.payload.id
        );
        draft.ArticleModels[key] = action.payload;
        break;
      case UPDATE_ARTICLETYPE:
        key = draft.ArticleTypes.findIndex((AT) => AT.id === action.payload.id);
        draft.ArticleTypes[key] = action.payload;
        draft.ArticleTypes[key].parentTypeName = draft.ArticleTypes.find(
          (AT) => AT.id === action.payload.parentId
        )?.articleTypeName;
        break;
      case UPDATE_MOVEMENTTYPE:
        key = draft.MovementTypes.findIndex(
          (AT) => AT.id === action.payload.id
        );
        draft.MovementTypes[key] = action.payload;
        break;
      case CREATE_MOVEMENT:
        key = draft.Articles.findIndex(
          (A) => A.id === action.payload.articleId
        );
        draft.Articles[key].movements = [
          ...draft.Articles[key].movements,
          action.payload,
        ];
        draft.Articles[key].movements.sort(
          (a, b) =>
            b.movementDate.localeCompare(a.movementDate) ||
            b.timeStamp.localeCompare(a.timeStamp)
        );
        break;
      case UPDATE_MOVEMENT:
        key = draft.Articles.findIndex(
          (A) => A.id === action.payload.articleId
        );
        subkey = draft.Articles[key].movements.findIndex(
          (M) => M.movementId === action.payload.movementId
        );
        draft.Articles[key].movements[subkey] = action.payload;
        draft.Articles[key].movements.sort(
          (a, b) =>
            b.movementDate.localeCompare(a.movementDate) ||
            b.timeStamp.localeCompare(a.timeStamp)
        );
        break;
      case UPDATE_LOCATION:
        key = draft.Locations.findIndex((AT) => AT.id === action.payload.id);
        draft.Locations[key] = action.payload;
        draft.Locations[key].parentLocationName = draft.Locations.find(
          (AT) => AT.id === action.payload.parentId
        )?.locationName;
        break;
      case UPDATE_REASON:
        key = draft.Reasons.findIndex((AT) => AT.id === action.payload.id);
        draft.Reasons[key] = action.payload;
        break;
      case UPDATE_STATE:
        key = draft.States.findIndex((AT) => AT.id === action.payload.id);
        draft.States[key] = action.payload;
        break;
      case DELETE_ARTICLEMODEL:
        key = draft.ArticleModels.findIndex((A) => A.id === action.payload.id);
        draft.ArticleModels.splice(key, 1);
        break;
      case DELETE_ARTICLETYPE:
        key = draft.ArticleTypes.findIndex((A) => A.id === action.payload.id);
        draft.ArticleTypes.splice(key, 1);
        break;
      case DELETE_MOVEMENT:
        key = draft.Articles.findIndex(
          (A) => A.id === action.payload.articleId
        );
        subkey = draft.Articles[key].movements.findIndex(
          (M) => M.movementId === action.payload.movementId
        );
        draft.Articles[key].movements.splice(subkey, 1);
        break;
      case UPLOAD_ARTICLE_PICTURE:
        key = draft.Articles.findIndex((A) => A.id === action.payload.id);
        draft.Articles[key].picture = action.payload.fileName;
        break;
      case UPLOAD_ARTICLEMODEL_PICTURE:
        key = draft.ArticleModels.findIndex((A) => A.id === action.payload.id);
        draft.ArticleModels[key].picture = action.payload.fileName;
        break;
      case UPLOAD_ARTICLETYPE_PICTURE:
        key = draft.ArticleTypes.findIndex((A) => A.id === action.payload.id);
        draft.ArticleTypes[key].picture = action.payload.fileName;
        break;
      default:
        return state;
    }
  });

export default articlesReducer;
